import isArray from 'lodash/isArray';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { I18nNs } from '@/util/i18n';

import { useMediaField } from './context';
import { DropzoneContainer } from './Dropzone.style';

type Props = {
  id: string;
  name: string;
  extensions: string[];
};

export function Dropzone({ id, name, extensions }: Props) {
  const { onDrop, showDropzone, multi } = useMediaField();
  const { t } = useTranslation(I18nNs.Field);
  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop,
  });

  if (!showDropzone) return null;

  return (
    <DropzoneContainer
      {...getRootProps({
        $isDragActive: isDragActive,
        $isDragAccept: isDragAccept,
        $isDragReject: isDragReject,
      })}
    >
      <input id={id} name={name} {...getInputProps()} multiple={multi} />
      {isDragActive ? (
        <p>{t('media.draghover')}</p>
      ) : (
        <>
          <p>{t('media.drag')}</p>
          {isArray(extensions) && extensions.length > 0 && (
            <p>{t('media.extensions', { extensions: extensions.join(', ') })}</p>
          )}
        </>
      )}
    </DropzoneContainer>
  );
}
