import { FunctionComponent, useMemo } from 'react';

import { ListHistoriesOutput } from '@/api/history/list-histories/list-histories.output';
import { HistoryEntity } from '@/enum';

import {
  HistoryRelationComptaArticleFamily,
  HistoryRelationDelivery,
  HistoryRelationOrderPayment,
  HistoryRelationOrderProduct,
  HistoryRelationOrderRecovery,
  HistoryRelationOrderSecondhand,
  HistoryRelationPriceType,
  HistoryRelationPurchaseCategory,
  HistoryRelationPurchaseOrder,
  HistoryRelationPurchaseOrderProduct,
  HistoryRelationPurchaseProduct,
  HistoryRelationPurchaseProductPackaging,
  HistoryRelationPurchaseSupplier,
  HistoryRelationPurchaseUnit,
  HistoryRelationRepairOrder,
  HistoryRelationRepairOrderOption,
  HistoryRelationRequestBy,
  HistoryRelationReseller,
  HistoryRelationResellerBy,
  HistoryRelationSecondhand,
  HistoryRelationSecondhandPlace,
  HistoryRelationTree,
  HistoryRelationUser,
  HistoryRelationVat,
  HistoryRelationVoucher,
} from './component';
import { HistoryRelationConfig } from './component/HistoryRelationConfig';
import { HistoryRelationCustomer } from './component/HistoryRelationCustomer';
import { HistoryRelationCustomerAddress } from './component/HistoryRelationCustomerAddress';
import { HistoryRelationOrder } from './component/HistoryRelationOrder';

type Props = {
  history: ListHistoriesOutput['docs'][0];
};

const relationOrder = [
  HistoryEntity.ResellerBy,
  HistoryEntity.RequestBy,

  HistoryEntity.Customer,
  HistoryEntity.Order,

  HistoryEntity.ComptaArticleFamily,
  HistoryEntity.Config,
  HistoryEntity.CustomerAddress,
  HistoryEntity.Delivery,
  HistoryEntity.OrderPayment,
  HistoryEntity.OrderProduct,
  HistoryEntity.OrderRecovery,
  HistoryEntity.OrderSecondhand,
  HistoryEntity.Payment,
  HistoryEntity.PriceType,
  HistoryEntity.PurchaseCategory,
  HistoryEntity.PurchaseOrder,
  HistoryEntity.PurchaseOrderProduct,
  HistoryEntity.PurchaseProduct,
  HistoryEntity.PurchaseProductPackaging,
  HistoryEntity.PurchaseSupplier,
  HistoryEntity.PurchaseUnit,
  HistoryEntity.RepairOrder,
  HistoryEntity.RepairOrderOption,
  HistoryEntity.Reseller,
  HistoryEntity.Secondhand,
  HistoryEntity.SecondhandPlace,
  HistoryEntity.Tree,
  HistoryEntity.User,
  HistoryEntity.Vat,
  HistoryEntity.Voucher,
];

const relationIndex = new Map<HistoryEntity, FunctionComponent<{ id: string }>>([
  [HistoryEntity.ComptaArticleFamily, HistoryRelationComptaArticleFamily],
  [HistoryEntity.Config, HistoryRelationConfig],
  [HistoryEntity.Customer, HistoryRelationCustomer],
  [HistoryEntity.CustomerAddress, HistoryRelationCustomerAddress],
  [HistoryEntity.Delivery, HistoryRelationDelivery],
  [HistoryEntity.Order, HistoryRelationOrder],
  [HistoryEntity.OrderPayment, HistoryRelationOrderPayment],
  [HistoryEntity.OrderProduct, HistoryRelationOrderProduct],
  [HistoryEntity.OrderRecovery, HistoryRelationOrderRecovery],
  [HistoryEntity.OrderSecondhand, HistoryRelationOrderSecondhand],
  [HistoryEntity.OrderPayment, HistoryRelationOrderPayment],
  [HistoryEntity.PriceType, HistoryRelationPriceType],
  [HistoryEntity.PurchaseCategory, HistoryRelationPurchaseCategory],
  [HistoryEntity.PurchaseOrder, HistoryRelationPurchaseOrder],
  [HistoryEntity.PurchaseOrderProduct, HistoryRelationPurchaseOrderProduct],
  [HistoryEntity.PurchaseProduct, HistoryRelationPurchaseProduct],
  [HistoryEntity.PurchaseProductPackaging, HistoryRelationPurchaseProductPackaging],
  [HistoryEntity.PurchaseSupplier, HistoryRelationPurchaseSupplier],
  [HistoryEntity.PurchaseUnit, HistoryRelationPurchaseUnit],
  [HistoryEntity.RepairOrder, HistoryRelationRepairOrder],
  [HistoryEntity.RepairOrderOption, HistoryRelationRepairOrderOption],
  [HistoryEntity.RequestBy, HistoryRelationRequestBy],
  [HistoryEntity.Reseller, HistoryRelationReseller],
  [HistoryEntity.ResellerBy, HistoryRelationResellerBy],
  [HistoryEntity.Secondhand, HistoryRelationSecondhand],
  [HistoryEntity.SecondhandPlace, HistoryRelationSecondhandPlace],
  [HistoryEntity.Tree, HistoryRelationTree],
  [HistoryEntity.User, HistoryRelationUser],
  [HistoryEntity.Vat, HistoryRelationVat],
  [HistoryEntity.Voucher, HistoryRelationVoucher],
]);

export function HistoryRelation({ history }: Props) {
  const relations = useMemo(
    () =>
      history.relationIds.sort(
        (a, b) =>
          relationOrder.findIndex((v) => v === a.entity) -
          relationOrder.findIndex((v) => v === b.entity),
      ),
    [history],
  );

  return (
    <>
      {relations.map(({ entity, relationId: id }) => {
        const Relation = relationIndex.get(entity);
        if (Relation) {
          return <Relation key={`${entity}_${id}`} id={id} />;
        }

        return null;
      })}
    </>
  );
}
