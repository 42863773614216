import { ChatLink } from '@/component/ChatLink/ChatLink';

import { useChatStore } from '../../store/chat.store';

export function LinkBtn() {
  const room = useChatStore((s) => s.room);

  if (!room) return null;

  return <ChatLink size="small" type={room.type} docId={room.docId} />;
}
