import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { useHeader } from '@/context';
import HomeIcon from '@/svg/home.svg';

import { StyledHome } from './Home.style';

export function Home() {
  const { isActive } = useHeader();

  return (
    <StyledHome className={clsx(isActive('/dashboard', 1) && 'is-active')}>
      <Link to="/dashboard">
        <span>
          <HomeIcon />
        </span>
      </Link>
    </StyledHome>
  );
}
