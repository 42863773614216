import { ReactElement } from 'react';
import { Control, FieldValues, Path } from 'react-hook-form';

import type { UseListAllOutput, UseOutput, UsePageOutput } from '@/api/common';
import { OptionType } from '@/type';

import { SelectSearchMulti, SelectSearchSingle } from './component';

interface Props<T extends FieldValues, List, Item> {
  name: Path<T>;
  label: string;
  control: Control<T>;
  useFind: (params?: object | null) => UsePageOutput<List> | UseListAllOutput<List>;
  useFindOne: (id: string | null) => UseOutput<Item>;
  dbToOptionType: (doc: List) => OptionType;
  findParams?: object;
  disabled?: boolean;
  loading?: boolean;
  multi?: boolean;
  size?: 'small' | 'medium';
}

export function SelectSearch<T extends FieldValues, List, Item>({
  multi = false,
  ...props
}: Props<T, List, Item>): ReactElement {
  if (multi) {
    return <SelectSearchMulti<T, List> {...props} />;
  }
  return <SelectSearchSingle<T, List, Item> {...props} />;
}
