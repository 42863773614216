import styled from 'styled-components';

import { themeConfig } from '@/util/theme';

export const StepIconComponent = styled.span<{ $isActive: boolean | undefined }>`
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: relative;
  top: 8px;
  background-color: ${({ $isActive }) =>
    $isActive ? themeConfig?.palette?.primary?.main : '#eaeaf0'};
`;
