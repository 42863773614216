import { AxiosProgressEvent } from 'axios';

import { axios } from '@/util/axios';
import { getUrl } from '@/util/get-url';
import { handleError } from '@/util/handle-error';

const path = '/media';

export const createMedia = async (
  file: File,
  onUploadProgress?: (progress: AxiosProgressEvent) => any,
): Promise<string> => {
  try {
    const form = new FormData();
    form.append('file', file);
    const url = getUrl(path);
    return await axios
      .post<{ id: string }>(url, form, { onUploadProgress })
      .then((res) => res.data?.id);
  } catch (err) {
    return handleError(path, err, { file, onUploadProgress });
  }
};
