import Button from '@mui/material/Button';
import React, { ReactNode } from 'react';
import { Control, FieldValues, useFormState } from 'react-hook-form';

import InvalidFormMessage from '../InvalidFormMessage';

type Props<T extends FieldValues> = {
  control: Control<T>;
  label: string | ReactNode;
  withInvalidFormMessage?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  size?: 'small' | 'medium' | 'large';
};

export function SubmitButton<T extends FieldValues>({
  control,
  label,
  withInvalidFormMessage = true,
  fullWidth = true,
  disabled = false,
  size,
}: Props<T>): React.ReactElement {
  const { errors } = useFormState({
    control,
  });

  const isValid = Object.keys(errors).length === 0;

  if (disabled) {
    return (
      <Button disabled type="submit" variant="contained" fullWidth={fullWidth}>
        {label}
      </Button>
    );
  }

  return (
    <>
      {withInvalidFormMessage && <InvalidFormMessage control={control} />}
      <Button
        size={size}
        disabled={!isValid}
        type="submit"
        variant="contained"
        fullWidth={fullWidth}
      >
        {label}
      </Button>
    </>
  );
}
