import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';

import Tooltip from '@/component/Tooltip';
import { useUserId } from '@/hook/user';
import { ChatOutput } from '@/socket/chat/dto';

type Line = ChatOutput['lines'][0];

type Props = {
  line: Line;
};

export function LastView({ line }: Props) {
  const meId = useUserId();

  const users = line.users.filter((u) => u.id !== meId);

  if (users.length === 0) return null;

  return (
    <Stack direction="row-reverse" spacing={1}>
      {users.map((u) => {
        return (
          <Tooltip key={u.id} title={u.name}>
            <Avatar sx={{ width: 24, height: 24, fontSize: '10px' }} src={u.avatar} alt={u.name}>
              {u?.initials}
            </Avatar>
          </Tooltip>
        );
      })}
    </Stack>
  );
}
