import { Skeleton } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { nanoid } from 'nanoid';
import { ReactElement, useState } from 'react';
import { Control, FieldValues, Path, useController } from 'react-hook-form';

import { UseListAllOutput, UseOutput, UsePageOutput } from '@/api/common';
import { ErrorMessage } from '@/field/ErrorMessage/ErrorMessage';
import { OptionType } from '@/type';

import { useOptions, useValue } from '../../hook';

interface Props<T extends FieldValues, List, Item> {
  name: Path<T>;
  label: string;
  control: Control<T>;
  useFind: (params?: object | null) => UsePageOutput<List> | UseListAllOutput<List>;
  useFindOne: (id: string | null) => UseOutput<Item>;
  findParams?: object;
  dbToOptionType: (data: List) => OptionType;
  loading?: boolean;
  disabled?: boolean;
  size?: 'small' | 'medium';
}

export function SelectSearchSingle<T extends FieldValues, List, Item>({
  control,
  label,
  name,
  useFind,
  useFindOne,
  dbToOptionType,
  findParams,
  loading = false,
  disabled = false,
  size,
}: Props<T, List, Item>): ReactElement {
  const [id] = useState(nanoid());
  const [search, setSearch] = useState<string>('');

  const {
    field: { onChange, ...field },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const value = useValue<List, Item>({
    value: loading ? null : (field.value as string),
    useFindOne,
    dbToOptionType,
  });

  const options = useOptions<List>({ search, findParams, useFind, dbToOptionType, value });

  return (
    <FormControl error={!!error} sx={{ width: '100%' }} variant="outlined">
      {loading && <Skeleton variant="rectangular" height={56} />}
      {!loading && (
        <Autocomplete
          id={id}
          getOptionLabel={(option) => (typeof option === 'string' ? option : option.label)}
          filterOptions={(x) => x}
          options={options}
          autoComplete
          includeInputInList
          value={value}
          disabled={disabled}
          filterSelectedOptions
          selectOnFocus
          size={size}
          autoHighlight
          freeSolo={false}
          popupIcon=""
          isOptionEqualToValue={(option, newValue) => {
            if (!option || !newValue) return false;
            return (option?.value || option) === (newValue?.value || newValue);
          }}
          noOptionsText="Rechercher..."
          onChange={(_event, newValue: OptionType | null) => {
            onChange({ target: { value: newValue?.value || null } });
          }}
          onInputChange={(_event, newInputValue) => {
            if (typeof newInputValue !== 'string') return setSearch('');
            if (newInputValue.length === 0) return setSearch('');
            return setSearch(newInputValue);
          }}
          renderInput={(params) => <TextField {...params} label={label} fullWidth />}
        />
      )}
      <ErrorMessage name={name} control={control} />
    </FormControl>
  );
}
