import { axios } from '@/util/axios';
import { getUrl } from '@/util/get-url';
import { handleError } from '@/util/handle-error';

import { uuidValidator } from '../../common/index.ts';

const path = '/media/:mediaId';

export const deleteMedia = async (mediaId: string): Promise<void> => {
  try {
    await uuidValidator.validate(mediaId);
    const url = getUrl(path, { mediaId });
    await axios.delete(url);
  } catch (err) {
    handleError(path, err, { mediaId });
  }
};
