import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-use';

type UseRedirectToLoginOutput = () => any;

export const useRedirectToLogin = (): UseRedirectToLoginOutput => {
  const navigate = useNavigate();
  const location = useLocation();

  const url = useMemo(() => {
    const disabled = ['/logout'];
    if (!location.pathname) return null;
    if (disabled.includes(location.pathname)) return null;

    if (location.href) {
      const redirectParam = new URL(location.href).searchParams.get('redirect');
      if (redirectParam) return redirectParam;
    }

    const u = [location.pathname, location.search, location.hash].join('');

    return encodeURIComponent(u);
  }, [location]);

  return useCallback(() => {
    const withRedirect = typeof url === 'string' && ![null, '/', '%2F'].includes(url);
    navigate(withRedirect ? `/?redirect=${url}` : '/');
  }, [navigate, url]);
};
