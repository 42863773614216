import { useMemo, useRef } from 'react';

import { UseOutput } from '@/api/common';
import { OptionType } from '@/type';

type Props<List, Item> = {
  value: string | null;
  useFindOne: (id: string | null) => UseOutput<Item>;
  dbToOptionType: (data: List) => OptionType;
};

export function useValue<List, Item>({
  value: fieldValue,
  useFindOne,
  dbToOptionType,
}: Props<List, Item>): OptionType | null {
  const { data, isLoading } = useFindOne(typeof fieldValue !== 'string' ? null : fieldValue);

  const oldData = useRef<OptionType>(null);
  const res = useMemo(() => {
    if (isLoading) return oldData.current;
    if (!data) return null;
    return dbToOptionType(data as List);
  }, [dbToOptionType, data, isLoading]);

  return res;
}
