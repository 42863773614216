import styled from 'styled-components';

export const StyledInfo = styled.div`
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0;
      padding: 0;
    }
  }

  .chat-link {
    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;
