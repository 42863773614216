import swr, { SWRResponse } from 'swr';

import { axios } from '@/util/axios';
import { toDate } from '@/util/date';
import { getUrl } from '@/util/get-url';
import { handleError } from '@/util/handle-error';

import { uuidValidator } from '../../common/index.ts';
import { GetTreeOutput } from './get-tree.output.ts';

const path = '/tree/:treeId';

export const getTree = async (treeId: string): Promise<GetTreeOutput> => {
  try {
    await uuidValidator.validate(treeId);
    const url = getUrl(path, { treeId });
    return await axios.get<GetTreeOutput>(url).then((res) => ({
      ...res.data,
      createdAt: toDate(res.data.createdAt),
      releasedAt: toDate(res.data.releasedAt),
      receivedAt: toDate(res.data.receivedAt),
    }));
  } catch (err) {
    return handleError(path, err, { treeId });
  }
};

export const useGetTree = (treeId: string | null): SWRResponse<GetTreeOutput> => {
  const url = treeId ? getUrl(path, { treeId }) : null;
  return swr<GetTreeOutput>(url, () => getTree(treeId as string));
};
