import { useCallback, useMemo, useState } from 'react';
import { useDebounce } from 'react-use';

import { ChatType } from '@/enum';
import { chatJoin, chatSocket } from '@/socket/chat';

const countIndex = new Map<string, number>();

export function useChatMessageNumber(type: ChatType | ChatType[], docId?: string | null): number {
  const [total, setTotal] = useState<number>(0);

  const types: ChatType[] = useMemo(() => (Array.isArray(type) ? type : [type]), [type]);

  const onCount = useCallback(
    (dto: { type: ChatType; docId: string; nb: number }) => {
      const key = `${dto.type}_${dto.docId}`;
      countIndex.set(key, dto.nb);
      if (docId !== dto.docId) return;
      if (!types.includes(dto.type)) return;
      setTotal(
        types.reduce<number>((acc, t) => {
          return acc + (countIndex.get(`${t}_${docId}`) || 0);
        }, 0),
      );
    },
    [types, docId],
  );

  useDebounce(
    () => {
      if (!docId) return undefined;
      types.map((t) => {
        chatJoin(t, docId);
        chatSocket.emit('getCountTotalMessages', { type: t, docId }, onCount);
      });
      chatSocket.on('onCountTotalMessagesChange', onCount);

      return () => {
        chatSocket.off('onCountTotalMessagesChange', onCount);
      };
    },
    80,
    [type, docId, onCount],
  );

  return total;
}
