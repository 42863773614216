import * as yup from 'yup';

export type UpdateMediaBodyInput = {
  name: string;
};

export const updateMediaBodyValidator = yup
  .object({
    name: yup.string().required(),
  })
  .required();
