import * as yup from 'yup';

export type ListChatUsersQueryInput = {
  s?: string;
  page?: number;
};

export const listChatUsersQueryValidator = yup
  .object({
    s: yup.string().optional(),
    page: yup.number().min(1).optional(),
  })
  .required();
