import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import nl2br from 'react-nl2br';

import { useUserLang } from '@/hook/user';
import { getDateString } from '@/util/date';
import { I18nNs } from '@/util/i18n';

export const useMessage = (message: string | null, isBot: boolean): ReactNode => {
  const { t } = useTranslation(I18nNs.Chat);
  const lang = useUserLang();

  if (typeof message !== 'string') return message;

  if (isBot) {
    try {
      const msg = JSON.parse(message);
      return t(msg.key, {
        ...msg,
        createdAt: getDateString(msg.createdAt, lang),
        ns: I18nNs.Chat,
      });
    } catch (_e) {
      return message;
    }
  }

  const key = `#${Date.now()}#`;

  const separedMessage = message.replace(/@\[[^\]]+\]\([^)]+\)/g, (v) => {
    return `${key}${v}${key}`;
  });

  const items = separedMessage.split(key).map((el, i) => {
    const id = `${el}_${i}`;
    if (!el.match(/@\[[^\]]+\]\([^)]+\)/)) return <span key={id}>{nl2br(el)}</span>;
    const as = el.replace(/@\[([^\]]+)\]\([^)]+\)/, '@$1');
    return (
      <span key={id} style={{ color: '#2405A0' }}>
        {as}
      </span>
    );
  });

  return items;
};
