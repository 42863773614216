import styled from 'styled-components';

export const StyledImageGallery = styled.div`
  .pswp {
    z-index: 99999 !important;
  }
  .images .pswp-thumbnails {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    img {
      max-width: 75px;
      max-height: 75px;
      margin: 5px;
    }
  }

  ul.files {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0;
      padding: 0;
      display: block;
      width: 100%;
      font-size: 0.9rem;
    }

    li a {
      display: flex;
      width: 100%;
      padding: 5px 0;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;
