import styled from 'styled-components';

export const ScrollableDiv = styled.div`
  overflow: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
`;

export const StyledChatScrollBottomBtn = styled.div`
  position: absolute;
  right: 20px;
  bottom: 0;
  opacity: 0;
  z-index: 9000;
  transition-property: bottom, opacity;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;

  &.sticky {
    opacity: 0.8;
    bottom: 20px;

    &:hover {
      opacity: 1;
    }
  }
`;
