import Color from 'color';
import styled, { css } from 'styled-components';

export const StyledMessage = styled.div`
  &:hover .delete {
    display: block;
  }
`;

const COLOR_ME = Color('#e65100').alpha(0.2).toString();
const COLOR_ME_BEFORE_DELETE = '#e65100';
const COLOR_NOT_ME = Color('#393939').alpha(0.2).toString();

export const StyledMessageBubble = styled.div<{ $me: boolean; $beforeDelete?: boolean }>`
  cursor: default;
  position: relative;
  color: #242424;
  padding: 10px;
  margin: 0px 10px;

  a {
    color: #242424;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    width: 0px;
    height: 0px;
    border-style: solid;
  }

  .user-name {
    font-size: 0.7rem;
    padding-bottom: 5px;
  }

  .date {
    display: block;
    font-size: 0.6rem;
    padding-top: 5px;
    text-align: right;
  }

  .delete {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-100%, 0%);
    display: none;
  }

  ${({ $me, $beforeDelete }) => {
    if ($me) {
      return css`
        background: ${$beforeDelete ? COLOR_ME_BEFORE_DELETE : COLOR_ME};
        border-radius: 5px 0 5px 5px;
        &:after {
          right: 0;
          border-width: 10px 10px 0 0;
          border-color: ${$beforeDelete ? COLOR_ME_BEFORE_DELETE : COLOR_ME} transparent transparent
            transparent;
          transform: rotate(0deg) translateX(100%);
        }
      `;
    }

    return css`
      background: ${COLOR_NOT_ME};
      border-radius: 0 5px 5px 5px;
      &:after {
        left: 0;
        border-width: 0 10px 10px 0;
        border-color: transparent ${COLOR_NOT_ME} transparent transparent;
        transform: rotate(0deg) translateX(-100%);
      }
    `;
  }}
`;
