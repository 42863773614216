import { useMemo } from 'react';

import { useListHistories } from '@/api/history/list-histories/list-histories.controller';

import { HistoryListItem } from './HistoryListItem';

type Props = {
  filters: any;
};

export function HistoryListPage({ filters }: Props) {
  const { data } = useListHistories({ ...filters, sort: 'createdAt', desc: 'createdAt' });
  const histories = useMemo(() => data?.docs || [], [data]);

  return (
    <>
      {(histories || []).map((history) => (
        <HistoryListItem key={history.id} history={history} />
      ))}
    </>
  );
}
