import styled from 'styled-components';

type ColorType = {
  // for styled-component
  $isDragAccept: boolean;
  $isDragReject: boolean;
  $isDragActive: boolean;
};

const getcolor = (props: ColorType): string => {
  if (props.$isDragAccept) {
    return '#00e676';
  }
  if (props.$isDragReject) {
    return '#ff1744';
  }
  if (props.$isDragActive) {
    return '#2196f3';
  }
  return '#eeeeee';
};

export const DropzoneContainer = styled.div<ColorType>`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-style: dashed;
  border-color: ${(props: ColorType) => getcolor(props)};
  margin: 0;
  background-color: #fff;
  border-radius: 2px;
  color: #bdbdbd;
  cursor: pointer;
  outline: none;
  transition: border 0.24s ease-in-out;

  p {
    margin: 5px;
  }
`;
