import { Close as DeleteIcon, Visibility as VisibilityIcon } from '@mui/icons-material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import debounce from 'lodash/debounce';
import prettyBytes from 'pretty-bytes';
import { useId, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetMedia } from '@/api/media/get-media';
import { updateMedia } from '@/api/media/update-media';
import Loader from '@/component/Loader';
import { useConfirm } from '@/context/ConfirmContext';
import { I18nNs } from '@/util/i18n';

import { useMediaField } from './context';
import { Input, MediaStyle, SquareImage } from './Media.style';

type Props = {
  mediaId: string;
};

export function Media({ mediaId }: Props) {
  const confirm = useConfirm();
  const { t } = useTranslation([I18nNs.Field, I18nNs.Common]);

  const { data: media, mutate } = useGetMedia(mediaId);

  const { onDelete, readOnly, draggable, updateNameField } = useMediaField();

  const update = useMemo(
    () =>
      debounce(async (name: string) => {
        if (!media) return;
        await updateMedia(media.id, { name });
        mutate({ ...media, name });
      }, 500),
    [media, mutate],
  );

  const id = useId();

  return (
    <MediaStyle className="sortable-draggable">
      {draggable && (
        <span className="draggable">
          <DragIndicatorIcon />
        </span>
      )}

      {media && media.isImage && (
        <div className="media-thumb">
          <SquareImage className="square-image" $img={media.urlMin} $crop={false} />
        </div>
      )}

      {media && (
        <div
          style={{
            width: '100%',
            marginRight: '20px',
            position: 'relative',
            display: 'grid',
            flexWrap: 'wrap',
          }}
        >
          {!media.urlMin && media.filename}
          {media.urlMin && (
            <>
              {updateNameField && (
                <div style={{ paddingRight: 10 }}>
                  <Input
                    type="text"
                    onChange={(e) => update(e.target.value)}
                    defaultValue={media.name}
                  />
                </div>
              )}
              {!updateNameField && (
                <a
                  className="media-filename"
                  href={media.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {media.filename}
                </a>
              )}
            </>
          )}
          {media.size && <div>({prettyBytes(media.size)})</div>}
        </div>
      )}

      {!readOnly && (
        <div style={{ marginLeft: 'auto' }}>
          {media && (
            <a
              className="show"
              href={media.url}
              aria-labelledby={`media-show-btn-${id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <VisibilityIcon id={`media-show-btn-${id}`} color="primary" />
            </a>
          )}

          {!media && <Loader size={30} />}

          <button
            className="delete link-button"
            aria-labelledby={`media-delete-btn-${id}`}
            type="button"
            onClick={async (e) => {
              e.preventDefault();
              const res = await confirm(
                t('delete.confirm', { name: media?.name || 'ce fichier', ns: I18nNs.Component }),
              );
              if (res === false) return;
              onDelete(mediaId);
            }}
            title={t('media.delete')}
          >
            <DeleteIcon id={`media-delete-btn-${id}`} />
          </button>
        </div>
      )}
    </MediaStyle>
  );
}
