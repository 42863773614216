import CategoryIcon from '@mui/icons-material/Category';
import { IconButton } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Tooltip } from '@/component/Tooltip/Tooltip';
import { UserRole } from '@/enum';
import { useUserHasSomeRole } from '@/hook/user';
import { I18nNs } from '@/util/i18n';

type Props = {
  id: string;
};

export function HistoryRelationPurchaseProduct({ id }: Props) {
  const { t } = useTranslation(I18nNs.History);
  const hasSomeRole = useUserHasSomeRole();

  const to = useMemo(() => {
    if (hasSomeRole(UserRole.Admin, UserRole.Purchase)) return `/purchase/product/show/${id}`;
    return null;
  }, [hasSomeRole, id]);

  if (!to) return null;

  return (
    <Tooltip title={t('btn.relation.show.purchase_product')}>
      <IconButton component={Link} to={to} size="small">
        <CategoryIcon />
      </IconButton>
    </Tooltip>
  );
}
