import AddIcon from '@mui/icons-material/Add';
import Timeline from '@mui/lab/Timeline';
import { timelineItemClasses } from '@mui/lab/TimelineItem';
import { Alert } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useMemo, useState } from 'react';

import { useListHistories } from '@/api/history/list-histories/list-histories.controller';
import { HistoryAction, HistoryEntity } from '@/enum';
import { getArray } from '@/util/getArray';

import { HistoryListPage } from './HistoryListPage';

export type HistoryListFilter = {
  actions?: HistoryAction[];
  entities?: HistoryEntity[];
  relationIds?: string[];
};

export type HistoryListProps = {
  dateLeft?: Date | null;
  dateRight?: Date | null;
  filters?: HistoryListFilter[];
  id?: string;
};

const has = (v: any): boolean => Array.isArray(v) && v.length > 0;

export function HistoryList({ dateLeft, dateRight, filters, id }: HistoryListProps) {
  const [page, setPage] = useState<number>(1);

  const query = useMemo(() => {
    const q: any = {};
    if (dateLeft) q.dateLeft = dateLeft;
    if (dateRight) q.dateRight = dateRight;

    if (typeof id === 'string') {
      q.filters = [{ relationIds: [id] }];
    }

    if (Array.isArray(filters)) {
      filters.forEach(({ actions, entities, relationIds }) => {
        const f: any = {};
        if (actions && has(actions)) f.actions = actions;
        if (entities && has(entities)) f.entities = entities;
        if (relationIds && has(relationIds)) f.relationIds = relationIds;
        if (Object.keys(f).length > 0) {
          q.filters = q.filters || [];
          q.filters.push(f);
        }
      });
    }
    return q;
  }, [dateLeft, dateRight, filters, id]);

  const { isLoading, data } = useListHistories({ ...query, page: 1 });
  const { totalDocs, totalPages, limit } = useMemo(
    () => ({
      totalDocs: data?.totalDocs || 0,
      totalPages: data?.totalPages || 1,
      limit: data?.limit || 15,
    }),
    [data],
  );

  // Preload next page
  useListHistories({ ...query, page: page + 1 });

  const next = useMemo(() => {
    if (page >= totalPages) return 0;
    const actual = page * limit;
    return totalDocs - actual;
  }, [page, totalDocs, totalPages, limit]);

  if (!isLoading && totalDocs === 0) {
    return <Alert severity="info">Aucun historique pour ce produit.</Alert>;
  }

  return (
    <Box>
      <Timeline
        sx={{
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
        }}
      >
        {getArray(page).map((p) => (
          <HistoryListPage key={p} filters={{ ...query, page: p + 1 }} />
        ))}
      </Timeline>

      <Stack direction="row" justifyContent="center" sx={{ mt: 4, mb: 4 }}>
        {page < totalPages && (
          <Button startIcon={<AddIcon />} variant="contained" onClick={() => setPage((p) => p + 1)}>
            Suite ({next})
          </Button>
        )}
      </Stack>
    </Box>
  );
}
