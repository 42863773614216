import swr, { SWRResponse } from 'swr';

import { axios } from '@/util/axios';
import { getUrl } from '@/util/get-url';
import { handleError } from '@/util/handle-error';

import { ListChatUsersQueryInput, listChatUsersQueryValidator } from './list-chat-users.input.ts';
import { ListChatUsersOutput } from './list-chat-users.output.ts';

const path = 'chat-user';

export const listChatUsers = async (
  query?: ListChatUsersQueryInput,
): Promise<ListChatUsersOutput> => {
  try {
    await listChatUsersQueryValidator.validate(query);
    const url = getUrl(path, query);
    return await axios.get<ListChatUsersOutput>(url).then((res) => res.data);
  } catch (err) {
    return handleError(path, err, { query });
  }
};

export const useListChatUsers = (
  query?: ListChatUsersQueryInput | null,
): SWRResponse<ListChatUsersOutput> => {
  const url = query === null ? null : getUrl(path, query);
  return swr<ListChatUsersOutput>(url, () => listChatUsers(query as ListChatUsersQueryInput));
};
