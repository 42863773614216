import { Step, StepButton, StepIconProps, StepLabel, Stepper } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetTree } from '@/api/tree/get-tree';
import { updateTree } from '@/api/tree/update-tree';
import { useConfirm } from '@/context';
import { TreeStatus } from '@/enum';
import { I18nNs } from '@/util/i18n';

import { StepIconComponent } from './TreeStatusGraph.style';

type Props = {
  treeId: string;
};

const steps: TreeStatus[] = [
  TreeStatus.Ordered,
  TreeStatus.Stocked,
  TreeStatus.Strapped,
  TreeStatus.Mounted,
  TreeStatus.Destroyed,
];

export function TreeStatusGraph({ treeId }: Props) {
  const { t } = useTranslation(I18nNs.Field);
  const confirm = useConfirm();

  const { data: tree, mutate } = useGetTree(treeId);

  const onChange = useCallback(
    (i: number) => async () => {
      if (!tree) return;
      if (steps.indexOf(tree?.status) === i) return;

      const oldStatus = t(`tree.status.${tree?.status}`);
      const newStatus = t(`tree.status.${steps[i]}`);

      const ok = await confirm(
        `Modifier le statut de l'arçon de "${oldStatus}" à "${newStatus}" ?`,
      );
      if (!ok) return;

      await updateTree(tree.id, { status: steps[i] });

      mutate({ ...tree, status: steps[i] });
    },
    [confirm, t, mutate, tree],
  );

  const icon = useCallback(
    ({ active }: StepIconProps) => <StepIconComponent $isActive={active} />,
    [],
  );

  if (!tree) {
    return null;
  }

  return (
    <Stepper alternativeLabel nonLinear activeStep={steps.indexOf(tree?.status)}>
      {steps.map((label, i) => {
        const isActive = i === steps.indexOf(tree?.status);

        return (
          <Step key={label} completed={i <= steps.indexOf(tree?.status)}>
            {isActive && (
              <StepLabel StepIconComponent={icon}>{t(`tree.status.${label}`)}</StepLabel>
            )}
            {!isActive && (
              <StepButton color="inherit" onClick={onChange(i)}>
                <StepLabel StepIconComponent={icon}>{t(`tree.status.${label}`)}</StepLabel>
              </StepButton>
            )}
          </Step>
        );
      })}
    </Stepper>
  );
}
