import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { ThreeDots } from 'react-loader-spinner';

import { useTyping } from '../../hook/useTyping';
import { useChatStore } from '../../store/chat.store';
import { StyledChatLine, StyledChatTyping } from './Lines.style';

export function Lines() {
  const lines = useChatStore((s) => s.chat?.lines);
  const typing = useTyping();

  if (!Array.isArray(lines)) return null;
  if (lines.length === 0) return null;

  return (
    <Stack spacing={2} sx={{ padding: 1 }}>
      {(lines || []).map((line) => (
        <StyledChatLine key={line.id} line={line} />
      ))}
      {typing && (
        <StyledChatTyping>
          <div className="typing-loader">
            <ThreeDots color="#393939" width={25} height={8} />
          </div>
          <Typography fontSize={12} variant="body2">
            {typing}
          </Typography>
        </StyledChatTyping>
      )}
    </Stack>
  );
}
