import { create } from 'zustand';

import { ChatType } from '@/enum';
import { ChatOutput } from '@/socket/chat/dto';
import { Room } from '@/value-object/room';

type ChatStore = {
  types: ChatType[];
  docId: string | null;
  room: Room | null;
  chat: ChatOutput | null;
  setDocId: (docId: string) => void;
  setTypes: (types: ChatType[]) => void;
  setRoom: (room: Room) => void;
  setChat: (chat: ChatOutput | null) => void;
  reset: () => void;
};

const chatStore = create<ChatStore>((set) => ({
  types: [],
  docId: null,
  room: null,
  chat: null,
  setDocId: (docId: string) => {
    set({ docId });
  },
  setTypes: (types: ChatType[]) => {
    set({ types });
  },
  setRoom: (room: Room) => {
    set({ room });
  },
  setChat: (chat: ChatOutput | null) => {
    set({ chat });
  },
  reset: () => {
    set({
      types: [],
      docId: null,
      room: null,
      chat: null,
    });
  },
}));

export const useChatStore = chatStore;
