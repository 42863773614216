import swr, { SWRResponse } from 'swr';

import { axios } from '@/util/axios';
import { getUrl } from '@/util/get-url';
import { handleError } from '@/util/handle-error';

import { uuidValidator } from '../../common/index.ts';
import { GetMediaOutput } from './get-media.output.ts';

const path = '/media/:mediaId';

export const getMedia = async (mediaId: string): Promise<GetMediaOutput> => {
  try {
    await uuidValidator.validate(mediaId);
    const url = getUrl(path, { mediaId });
    return await axios.get<GetMediaOutput>(url).then((res) => res.data);
  } catch (err) {
    return handleError(path, err, { mediaId });
  }
};

export const useGetMedia = (mediaId: string | null): SWRResponse<GetMediaOutput> => {
  const url = mediaId ? getUrl(path, { mediaId }) : null;
  return swr<GetMediaOutput>(url, () => getMedia(mediaId as string));
};
