import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { Stack } from '@mui/material';
import { ReactNode, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useConfirm } from '@/context';
import { usePersistState } from '@/hook';
import { I18nNs } from '@/util/i18n';

import { useActualApiVersion } from './useActualApiVersion';
import { useActualAppVersion } from './useActualAppVersion';
import { useCanRefresh } from './useCanRefresh';
import { useLastAppVersion } from './useLastAppVersion';

export const NewVersion = (): ReactNode => {
  const [lastShow, setLastShow] = usePersistState<string>('last_version', '-');

  const actualAppVersion = useActualAppVersion();
  const actualApiVersion = useActualApiVersion();
  const lastAppVersion = useLastAppVersion();

  const { t } = useTranslation(I18nNs.Common);

  const confirm = useConfirm();

  const canRefresh = useCanRefresh(actualAppVersion, actualApiVersion, lastAppVersion);

  const lastKey = useMemo(() => {
    return JSON.stringify({
      actualAppVersion,
      actualApiVersion,
      lastAppVersion,
    });
  }, [actualAppVersion, actualApiVersion, lastAppVersion]);

  const onRefresh = useCallback(async () => {
    if (lastShow === lastKey) return;

    const ok = await confirm(t('version.UpdateMessage', { lastAppVersion }), {
      ok: (
        <Stack direction="row" alignItems="center" spacing={1}>
          <div>{t('version.ok')}</div>
          <ThumbUpIcon fontSize="small" />
        </Stack>
      ),
      ko: t('version.ko'),
    });
    setLastShow(lastKey);
    if (!ok) return;
    window.location.reload();
  }, [confirm, lastShow, lastKey, setLastShow, t, lastAppVersion]);

  useEffect(() => {
    if (!canRefresh) return;
    const id = setTimeout(() => {
      onRefresh();
    }, 2_000);

    return () => {
      clearTimeout(id);
    };
  }, [canRefresh, onRefresh]);

  return null;
};
