import { IconButton } from '@mui/material';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Tooltip } from '@/component/Tooltip/Tooltip';
import { TreePopup } from '@/component/TreePopup/TreePopup';
import { UserRole } from '@/enum';
import { useUserHasSomeRole } from '@/hook/user';
import { I18nNs } from '@/util/i18n';

type Props = {
  id: string;
};

export function HistoryRelationTree({ id }: Props) {
  const [show, setShow] = useState<boolean>(false);
  const { t } = useTranslation(I18nNs.History);
  const hasSomeRole = useUserHasSomeRole();

  const to = useMemo(() => {
    if (hasSomeRole(UserRole.God)) return `/admin/user/edit/${id}`;
    if (hasSomeRole(UserRole.AdminReseller)) return `/reseller/user/edit/${id}`;
    return null;
  }, [hasSomeRole, id]);

  if (!to) return null;

  return (
    <>
      <Tooltip title={t('btn.relation.show.tree')}>
        <IconButton onClick={() => setShow(true)} size="small">
          <img src="/arcon.svg" width="24" height="24" alt="Arçon" />
        </IconButton>
      </Tooltip>
      {show && <TreePopup treeId={id} onClose={() => setShow(false)} />}
    </>
  );
}
