import { axios } from '@/util/axios';
import { getUrl } from '@/util/get-url';
import { handleError } from '@/util/handle-error';

import { uuidValidator } from '../../common/index.ts';
import { UpdateTreeBodyInput, updateTreeBodyValidator } from './update-tree.input.ts';

const path = '/tree/:treeId';

export const updateTree = async (treeId: string, body: UpdateTreeBodyInput): Promise<void> => {
  try {
    await Promise.all([uuidValidator.validate(treeId), updateTreeBodyValidator.validate(body)]);
    const url = getUrl(path, { treeId });
    await axios.patch(url, body);
  } catch (err) {
    handleError(path, err, { treeId, body });
  }
};
