import Color from 'color';
import styled from 'styled-components';

export const List = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;

  .sortable-gost {
    opacity: 0.3;
  }

  .draggable {
    display: flex;
    margin-right: 5px;
    cursor: grab;

    &:hover svg {
      color: #1976d2;
    }
  }

  li {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 5px;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0 0 0 / 12%);
    margin: 3px 0;
    background-color: ${Color('#ffffff').fade(0.5).toString()};
    border-radius: 2px;

    div.media-thumb {
      position: relative;
      margin-right: 15px;
    }

    div:last-child {
      display: flex;
      align-items: center;
      padding-left: auto;
    }

    .loading {
      position: relative;
    }

    .link-button {
      display: inline;
      padding: 0;
      border: none;
      margin: 0;
      background-color: transparent;
      cursor: pointer;
      text-decoration: underline;
    }

    button.delete,
    a.show {
      display: inline-block;
      height: 32px;
      padding: 0 10px;
      margin: 0;

      svg {
        display: inline-block;
        width: 32px;
        height: 32px;
        padding: 0;
        margin: 0;
      }

      &.delete:hover {
        color: red;
      }

      &.show:hover {
        color: #1976d2;
      }
    }

    &:hover {
      background-color: #eee;

      .square-image {
        border-color: white;
      }
    }
  }
`;
