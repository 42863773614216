import { useCallback, useEffect } from 'react';

import { useAppIsFocus } from '@/hook';
import { chatJoin, chatSocket } from '@/socket/chat';
import { ChatOutput } from '@/socket/chat/dto';

import { useChatStore } from '../store/chat.store';

export function useChat(): ChatOutput | null {
  const chat = useChatStore((s) => s.chat);
  const setChat = useChatStore((s) => s.setChat);
  const room = useChatStore((s) => s.room);

  const isFocus = useAppIsFocus();

  useEffect(() => {
    if (!chat) return;
    chatSocket.emit('readRoom', { type: chat.type, docId: chat.docId });
  }, [chat, isFocus]);

  const onChat = useCallback(
    (v: ChatOutput) => {
      if (!room) return;
      if (v.type !== room.type) return;
      if (v.docId !== room.docId) return;
      setChat(v);
    },
    [room, setChat],
  );

  useEffect(() => {
    if (room && isFocus) {
      chatSocket.emit('getChat', room.toJSON());
    }
  }, [room, isFocus]);

  useEffect(() => {
    if (!room) return;
    chatJoin(room.type, room.docId);
    chatSocket.on('onChat', onChat);
    chatSocket.emit('getChat', room.toJSON());
    return () => {
      chatSocket.off('onChat', onChat);
    };
  }, [room, onChat]);

  return chat;
}
