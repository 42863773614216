import SendIcon from '@mui/icons-material/Send';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Mention, MentionsInput } from 'react-mentions';

import { listChatUsers } from '@/api/chat/list-chat-users';
import { chatSocket } from '@/socket/chat';
import { ChatOutput } from '@/socket/chat/dto';

import { useChatStore } from '../../store/chat.store';
import classNames from './InputText.module.css';

const defaultMembers: ChatOutput['members'][0][] = [];

export function InputText() {
  const [value, setValue] = useState<string>('');
  const inputRef = useRef<HTMLInputElement>(null);
  const [isTyping, setIsTyping] = useState<boolean>(false);

  const members = useChatStore((s) => s?.chat?.members || defaultMembers);
  const room = useChatStore((s) => s.room);

  const hasFirstTypingIsCall = useRef<boolean>(false);
  useEffect(() => {
    if (!room) return;
    if (hasFirstTypingIsCall.current || isTyping) {
      hasFirstTypingIsCall.current = true;
      chatSocket.emit('userTyping', { ...room.toJSON(), stop: !isTyping });
    }
  }, [room, isTyping]);

  const autoStopTyping = useMemo(() => debounce(() => setIsTyping(false), 5000), []);

  const onTyping = useMemo(
    () =>
      throttle(() => {
        if (!inputRef.current) return;
        const newIsTyping = inputRef.current?.value.trim() !== '';

        if (newIsTyping) autoStopTyping();
        if (newIsTyping !== isTyping) setIsTyping(newIsTyping);
      }, 400),
    [isTyping, autoStopTyping],
  );

  useEffect(() => {
    if (!inputRef.current) return;
    const id = setTimeout(() => {
      if (!inputRef.current) return;
      inputRef.current.focus();
    }, 100);

    return () => {
      clearTimeout(id);
    };
  }, []);

  const sendMessage = () => {
    if (value.trim() === '') {
      setValue('');
      setIsTyping(false);
      return;
    }
    if (!room) return;
    chatSocket.emit('sendMessage', { ...room.toJSON(), message: value });
    setValue('');
    setIsTyping(false);
  };

  const displayMembers = useMemo(() => {
    return members.map((m) => ({
      id: m.id,
      display: m.name,
    }));
  }, [members]);

  const displayMembersIndex = useMemo(
    () => new Map(displayMembers.map((m, id) => [m.id, id])),
    [displayMembers],
  );

  const getSuggestion: Parameters<typeof Mention>['0']['data'] = async (s: string, cb) => {
    if (s.trim().length === 0) {
      return cb(displayMembers);
    }
    const users = await listChatUsers({ s });
    cb(
      users.docs
        .map((u) => ({
          id: u.id,
          display: u.fullName,
        }))
        .sort((a, b) => {
          const apos = displayMembersIndex.get(a.id);
          const bpos = displayMembersIndex.get(b.id);
          if (apos === undefined && bpos !== undefined) return 1;
          if (apos !== undefined && bpos === undefined) return -1;
          return 0;
        }),
    );
  };

  return (
    <Stack direction="row" alignItems="center">
      <MentionsInput
        style={{ margin: 0, borderTopLeftRadius: 0, width: '100%' }}
        inputRef={inputRef}
        value={value}
        autoFocus
        className="mentions"
        classNames={classNames}
        onChange={(_e, newValue) => setValue(newValue)}
        onKeyUp={() => {
          if (inputRef.current !== null) {
            onTyping();
          }
        }}
        onKeyDown={(e: any) => {
          if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            sendMessage();
          }
        }}
        customSuggestionsContainer={(children) => <div>{children}</div>}
      >
        <Mention
          trigger="@"
          className={classNames.mentions__mention}
          data={getSuggestion}
          displayTransform={(_id, display) => `@${display}`}
        />
      </MentionsInput>
      <IconButton onClick={sendMessage} disabled={value === ''}>
        <SendIcon />
      </IconButton>
    </Stack>
  );
}
