import uniqBy from 'lodash/uniqBy';
import { useMemo, useRef } from 'react';

import { UseListAllOutput, UsePageOutput } from '@/api/common';
import { OptionType } from '@/type';

type Props<List> = {
  search: string;
  findParams?: object;
  useFind: (params?: object | null) => UseListAllOutput<List> | UsePageOutput<List>;
  dbToOptionType: (data: List) => OptionType;
  values?: OptionType[] | null;
  value?: OptionType | null;
};

export function useOptions<List>({
  search,
  findParams,
  useFind,
  dbToOptionType,
  values: valuesProps = [],
  value: valueProps,
}: Props<List>): OptionType[] {
  const values: OptionType[] = useMemo(() => {
    const options = [];
    if (valueProps) options.push(valueProps);
    if (Array.isArray(valuesProps)) options.push(...valuesProps);
    return options;
  }, [valuesProps, valueProps]);

  const findPageParams = useMemo(() => {
    if (typeof search !== 'string') return findParams;
    if (search.length === 0) return findParams;
    return {
      ...findParams,
      s: search,
    };
  }, [search, findParams]);

  const { data } = useFind(findPageParams);
  const oldOptions = useRef<OptionType[]>([]);
  const options: OptionType[] = useMemo(() => {
    if (Array.isArray(data)) return data.map(dbToOptionType) as OptionType[];
    if (Array.isArray(data?.docs)) return data?.docs.map(dbToOptionType) as OptionType[];
    return oldOptions.current as OptionType[];
  }, [dbToOptionType, data]);
  oldOptions.current = options;

  return uniqBy([...values, ...options], 'value');
}
