import styled from 'styled-components';

import { Input, ScrollToBottom } from './component';

export const StyledChat = styled.section`
  display: grid;
  grid-template-columns: 2fr 50px 1fr;
  grid-template-rows: auto 1fr 50px auto;
  z-index: 9000;

  border: 1px solid rgba(0 0 0 / 10%);
  border-radius: 0 5px 5px 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  min-height: 600px;
`;
// height: 100%;

export const StyledChatHeader = styled.div`
  grid-column: 1 / 3;
  grid-row: 1 / 2;
  padding: 10px;

  border-bottom: 1px solid rgba(0 0 0 / 10%);
  background-color: #ffffff;
  position: relative;
`;

export const StyledChatNotExists = styled.div`
  grid-column: 1 / 4;
  grid-row: 2 / 4;
  z-index: 9000;
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledChatMessages = styled(ScrollToBottom)`
  grid-column: 1 / 3;
  grid-row: 2 / 4;
  z-index: 9000;
  width: 100%;
  min-height: 100%;
  position: relative;
  overflow: hidden;

  .chat-messages {
    padding-top: 60px;
  }

  .hide {
    display: none;
  }

  .chat-message-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;

export const StyledChatInput = styled(Input)`
  grid-column: 1 / 4;
  grid-row: 4 / 5;
  border-top: 1px solid rgba(0 0 0 / 10%);
`;

export const StyledChatInfo = styled.div`
  grid-column: 3 / 4;
  grid-row: 1 / 4;
  border-left: 1px solid rgba(0 0 0 / 10%);
  padding: 15px;
  overflow: auto;
`;
