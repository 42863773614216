import { Box, InputLabel } from '@mui/material';
import { nanoid } from 'nanoid';
import { useState } from 'react';

import { MediaFieldProvider } from './context';
import { Dropzone } from './Dropzone';
import { Loader } from './Loader';
import { Medias } from './Medias';

type Props = {
  id?: string;
  name: string;
  extensions?: string[];
  label: string;
  readOnly?: boolean;
  onChange: (value: string[]) => any;
  defaultValue?: string[];
  updateNameField?: boolean;
};

export function RawMany({
  extensions = [],
  id: baseId,
  label,
  name,
  readOnly = false,
  onChange,
  defaultValue = [],
  updateNameField = true,
}: Props) {
  const [id] = useState(baseId || nanoid());

  return (
    <MediaFieldProvider
      readOnly={readOnly}
      multi
      onChange={onChange}
      defaultValue={defaultValue}
      updateNameField={updateNameField}
      extensions={extensions}
    >
      <Box>
        <InputLabel htmlFor={id}>{label}</InputLabel>
        <Medias />
        <Loader />
        <Dropzone id={id} name={name} extensions={extensions} />
      </Box>
    </MediaFieldProvider>
  );
}
