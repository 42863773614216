import { Button, Paper, Stack } from '@mui/material';
import { KeyboardEvent, useCallback, useEffect, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { useGetPurchaseProduct } from '@/api/purchase/get-purchase-product';
import { useGetTree } from '@/api/tree/get-tree';
import { updateTree } from '@/api/tree/update-tree';
import { useConfirm, useSnack } from '@/context';
import { handleError } from '@/util/handle-error';

import { TreeSizeDto, treeSizeToDto } from './TreeSizeForm.dto';
import { TreeSizeFormField } from './TreeSizeFormField';

type Props = {
  treeId: string;
  onFinish?: () => any;
  onClose?: () => any;
};

export function TreeSizeForm({ treeId, onFinish, onClose }: Props) {
  const { data: tree, mutate } = useGetTree(treeId);
  const { data: purchaseProduct } = useGetPurchaseProduct(tree?.purchaseProductId || null);

  const treeModel = purchaseProduct?.treeModel;

  const confirm = useConfirm();
  const snack = useSnack();
  const methods = useForm<TreeSizeDto>({
    defaultValues: treeSizeToDto(tree || null),
  });
  const { control, reset, handleSubmit, setFocus } = methods;

  useEffect(() => {
    setFocus('received.largeur');
  }, [setFocus]);

  const oldTreeId = useRef<string | null>(null);
  useEffect(() => {
    if (oldTreeId.current === treeId) return;
    if (!tree) return;
    if (!reset) return;
    oldTreeId.current = treeId;
    reset(treeSizeToDto(tree));
  }, [treeId, reset, tree]);

  const onSubmit = useCallback(
    async (data: TreeSizeDto) => {
      if (!tree) return;
      const ok = await confirm(
        `Êtes-vous sûr de vouloir modifier les cotes de l'arçon A${tree?.number} ?`,
      );
      if (!ok) return;

      try {
        await updateTree(tree?.id, {
          received: data?.received,
          strapping: data?.strapping,
        });

        mutate();
        snack.success(`Les cotes ont bien été enregistrées`);
        if (typeof onFinish === 'function') onFinish();
      } catch (err) {
        handleError('../../../../component/TreePopup/component/TreeSize/TreeSizeForm', err);
        snack.error(`Impossible d'enregistrer les côtes de l'arçon.`);
      }
    },
    [confirm, mutate, snack, tree, onFinish],
  );

  const goNextWhenPressEnter = useCallback(
    (
        nextKey:
          | 'received.enfourchure'
          | 'received.longueur'
          | 'received.hauteurArcade'
          | 'received.hauteurTroussequin'
          | 'received.ouvertureArcade',
      ) =>
      (e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (e.key !== 'Enter') return;
        setFocus(nextKey);
      },
    [setFocus],
  );

  const goSubmitWhenPressEnter = useCallback(
    async (e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (e.key !== 'Enter') return;
      setFocus('strapping.largeur');
      await handleSubmit(onSubmit)();
      if (typeof onClose === 'function') onClose();
    },
    [handleSubmit, onSubmit, setFocus, onClose],
  );

  return (
    <FormProvider {...methods}>
      <Paper sx={{ padding: 2, mt: 2 }} key={tree?.status}>
        <form>
          <Stack justifyContent="space-between">
            <legend>Cotes et mesures</legend>
            <h2>Avant sanglage</h2>
            <Stack spacing={2} direction="row" sx={{ mb: 2 }}>
              <TreeSizeFormField
                name="received.enfourchure"
                control={control}
                label="Enfourchure"
                best={treeModel?.defaultReceived?.enfourchure}
                onKeyUp={goNextWhenPressEnter('received.longueur')}
              />
              <TreeSizeFormField
                name="received.longueur"
                control={control}
                label="Longueur"
                best={treeModel?.defaultReceived?.longueur}
                onKeyUp={goNextWhenPressEnter('received.hauteurArcade')}
              />
              <TreeSizeFormField
                name="received.hauteurArcade"
                control={control}
                label="Hauteur arcade"
                best={treeModel?.defaultReceived?.hauteurArcade}
                onKeyUp={goNextWhenPressEnter('received.hauteurTroussequin')}
              />
              <TreeSizeFormField
                name="received.hauteurTroussequin"
                control={control}
                label="Hauteur troussequin"
                best={treeModel?.defaultReceived?.hauteurTroussequin}
                onKeyUp={goNextWhenPressEnter('received.ouvertureArcade')}
              />
              <TreeSizeFormField
                name="received.ouvertureArcade"
                control={control}
                label="Ouverture arcade"
                best={treeModel?.defaultReceived?.ouvertureArcade}
                onKeyUp={goSubmitWhenPressEnter}
              />
            </Stack>

            <h2>Après sanglage</h2>
            <Stack spacing={2} direction="row">
              <TreeSizeFormField
                name="strapping.enfourchure"
                control={control}
                label="Enfourchure"
                best={treeModel?.defaultStrapping?.enfourchure}
              />
              <TreeSizeFormField
                name="strapping.longueur"
                control={control}
                label="Longueur"
                best={treeModel?.defaultStrapping?.longueur}
              />
              <TreeSizeFormField
                name="strapping.hauteurArcade"
                control={control}
                label="Hauteur arcade"
                best={treeModel?.defaultStrapping?.hauteurArcade}
              />
              <TreeSizeFormField
                name="strapping.hauteurTroussequin"
                control={control}
                label="Hauteur troussequin"
                best={treeModel?.defaultStrapping?.hauteurTroussequin}
              />
              <TreeSizeFormField
                name="strapping.ouvertureArcade"
                control={control}
                label="Ouverture arcade"
                best={treeModel?.defaultStrapping?.ouvertureArcade}
              />
            </Stack>
            <Stack direction="row" justifyContent="flex-end" sx={{ mt: 4 }}>
              <div style={{ width: 'calc((100% / 6) - 16px)' }}>
                <Button onClick={handleSubmit(onSubmit)} variant="contained">
                  Enregistrer
                </Button>
              </div>
            </Stack>
          </Stack>
        </form>
      </Paper>
    </FormProvider>
  );
}
