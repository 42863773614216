import BuildIcon from '@mui/icons-material/Build';
import CategoryIcon from '@mui/icons-material/Category';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import PersonIcon from '@mui/icons-material/Person';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import IconButton from '@mui/material/IconButton';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { ChatType } from '@/enum';

import { TreeItem } from '../TreeItem/TreeItem';

type ChatLinkItemProps = {
  format: 'link' | 'btn';
  title?: string;
  icon: ReactNode;
  to: string;
  size?: 'small';
};

function ChatLinkItem({ size, format, title, icon, to }: ChatLinkItemProps) {
  if (format === 'link') {
    return <Link to={to}>{title}</Link>;
  }

  return (
    <IconButton size={size} component={Link} to={to}>
      {icon}
    </IconButton>
  );
}

type Props = {
  type: ChatType;
  docId: string;
  format?: 'link' | 'btn';
  title?: string;
  size?: 'small';
};

export function ChatLink({ type, docId, title, format = 'btn', size }: Props) {
  if (type === ChatType.CustomerReport) {
    return (
      <ChatLinkItem
        format={format}
        title={title}
        size={size}
        icon={<PersonIcon />}
        to={`/customer/show/${docId}`}
      />
    );
  }

  if (type === ChatType.MyNote) {
    return <ChatLinkItem format={format} title={title} icon={<NoteAltIcon />} to="/me/profil" />;
  }

  if (type === ChatType.Order) {
    return (
      <ChatLinkItem
        format={format}
        title={title}
        icon={<ShoppingCartIcon />}
        to={`/order/show/${docId}`}
      />
    );
  }

  if (type === ChatType.OrderLine) {
    return (
      <ChatLinkItem
        format={format}
        title={title}
        icon={<CategoryIcon />}
        to={`/order/product/show/${docId}`}
      />
    );
  }

  // TODO: Redirect to voucher ?
  if (type === ChatType.OrderLineWorkshop) {
    return (
      <ChatLinkItem
        format={format}
        title={title}
        icon={<CategoryIcon />}
        to={`/order/product/show/${docId}`}
      />
    );
  }

  if (type === ChatType.RepairOrder) {
    return (
      <ChatLinkItem
        format={format}
        title={title}
        icon={<BuildIcon />}
        to={`/order/repair/show/${docId}`}
      />
    );
  }

  if (type === ChatType.Tree) {
    if (size === 'small') {
      return (
        <TreeItem
          treeId={docId}
          label={<img src="/arcon.svg" width="24" height="24" alt="Arçon" />}
        />
      );
    }
    return <TreeItem treeId={docId} label={title} />;
  }

  return <>-</>;
}
