export enum OrderLineType {
  Product = 'product',
  ProductOption = 'product-option',
  Secondhand = 'secondhand',
  Recovery = 'recovery',
  ResellerRecovery = 'reseller-recovery',
  Discount = 'discount',
}

export const orderLineTypeValues = Object.values(OrderLineType);

export const orderLineTypeMaxLength = Math.max(...orderLineTypeValues.map((v) => v.length));
