import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useCallback, useState } from 'react';

import { RawMany } from '@/field/Media/RawMany';
import { chatSocket } from '@/socket/chat';

import { useChatStore } from '../../store/chat.store';

export function InputFile() {
  const [medias, setMedias] = useState<string[]>([]);

  const room = useChatStore((s) => s.room);

  const sendMedia = useCallback(() => {
    if (!Array.isArray(medias)) return;
    if (medias.length === 0) return;
    if (!room) return;
    chatSocket.emit('sendMessage', { ...room.toJSON(), mediasIds: medias });
    setMedias([]);
  }, [room, medias]);

  const isEnabled = Array.isArray(medias) && medias.length > 0;

  return (
    <Stack direction="row" alignItems="stretch">
      <RawMany
        name="medias"
        label=""
        extensions={['jpeg', 'jpg', 'pdf', 'png', 'mp4']}
        updateNameField={false}
        onChange={(value: string[]) => {
          setMedias(value);
        }}
        defaultValue={medias}
      />
      <Button onClick={sendMedia} disabled={!isEnabled}>
        <SendIcon />
      </Button>
    </Stack>
  );
}
