import { createContext } from 'react';

export type MediaFieldContextType = {
  medias: string[];
  onDrop: (files: File[]) => any;
  onDelete: (id: string) => any;
  draggable: boolean;
  readOnly: boolean;
  onSwap: (a: number, b: number) => any;
  multi: boolean;
  showDropzone: boolean;
  isFinish: boolean;
  pc: number;
  updateNameField: boolean;
};

export const MediaFieldContext = createContext<MediaFieldContextType>({
  medias: [],
  onDrop: () => true,
  onSwap: () => true,
  onDelete: () => true,
  draggable: false,
  readOnly: false,
  multi: false,
  showDropzone: false,
  isFinish: true,
  pc: 100,
  updateNameField: true,
});
