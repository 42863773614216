import { axios } from '@/util/axios';
import { getUrl } from '@/util/get-url';
import { handleError } from '@/util/handle-error';

import { uuidValidator } from '../../common/index.ts';
import { UpdateMediaBodyInput, updateMediaBodyValidator } from './update-media.input.ts';

const path = '/media/:mediaId';

export const updateMedia = async (mediaId: string, body: UpdateMediaBodyInput): Promise<void> => {
  try {
    await Promise.all([uuidValidator.validate(mediaId), updateMediaBodyValidator.validate(body)]);
    const url = getUrl(path, { mediaId });
    await axios.patch(url, body);
  } catch (err) {
    handleError(path, err, { mediaId, body });
  }
};
