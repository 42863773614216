import { Box, InputAdornment, Stack, TextField } from '@mui/material';
import { nanoid } from 'nanoid';
import React, { KeyboardEvent, ReactElement, useMemo, useState } from 'react';
import { Control, FieldValues, Path, useController } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';

import { ErrorMessage } from '../ErrorMessage/ErrorMessage';

type Props<T extends FieldValues> = {
  control: Control<T>;
  disabled?: boolean;
  label: string;
  name: Path<T>;
  startAdornment?: string | ReactElement;
  endAdornment?: string | ReactElement;
  adornment?: string | ReactElement;
  decimalSeparator?: string;
  decimalScale?: number;
  thousandSeparator?: string;
  autoFocus?: boolean;
  helperText?: string;
  size?: 'small' | 'medium';
  min?: number;
  max?: number;
  onKeyUp?: (e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => any;
};

export function InputNumber<T extends FieldValues>({
  control,
  disabled = false,
  label,
  name,
  startAdornment,
  endAdornment,
  adornment,
  decimalSeparator = '.',
  decimalScale,
  thousandSeparator = ' ',
  min,
  max,
  autoFocus,
  helperText,
  size,
  onKeyUp,
}: Props<T>): React.ReactElement {
  const [id] = useState(nanoid());

  const {
    field: { ref, onChange, ...field },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const start: string | ReactElement | null = useMemo(() => {
    if (startAdornment) return startAdornment;
    if (adornment) return adornment;
    return null;
  }, [startAdornment, adornment]);

  return (
    <Stack alignItems="column" flex={1}>
      <Box>
        <NumericFormat
          customInput={TextField}
          id={id}
          fullWidth
          error={!!error}
          variant="outlined"
          disabled={disabled}
          size={size}
          label={label}
          autoFocus={autoFocus}
          {...field}
          value={typeof Number(field.value) === 'number' ? Number(field.value) : 0}
          onValueChange={(values) => {
            onChange({
              target: {
                name,
                value: values.floatValue || 0,
              },
            });
          }}
          inputRef={ref}
          isAllowed={(values) => {
            const { floatValue } = values;
            if (floatValue === undefined) return true;
            if (typeof min === 'number' && floatValue < min) return false;
            if (typeof max === 'number' && max < floatValue) return false;
            return true;
          }}
          allowedDecimalSeparators={['.', ',']}
          decimalSeparator={decimalSeparator}
          thousandSeparator={thousandSeparator}
          decimalScale={decimalScale}
          helperText={helperText}
          autoComplete="off"
          InputProps={{
            startAdornment: start && <InputAdornment position="start">{start}</InputAdornment>,
            endAdornment: endAdornment && (
              <InputAdornment position="end">{endAdornment}</InputAdornment>
            ),
            onKeyUp,
          }}
        />
      </Box>
      <Box>
        <ErrorMessage name={name} control={control} />
      </Box>
    </Stack>
  );
}
