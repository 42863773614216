import swr, { SWRResponse } from 'swr';

import { axios } from '@/util/axios';
import { toDate } from '@/util/date';
import { getUrl } from '@/util/get-url';
import { handleError } from '@/util/handle-error';

import { uuidValidator } from '../../common/index.ts';
import { GetPurchaseOrderOutput } from './get-purchase-order.output.ts';

const path = '/purchase-order/:purchaseOrderId';

export const getPurchaseOrder = async (
  purchaseOrderId: string,
): Promise<GetPurchaseOrderOutput> => {
  try {
    await uuidValidator.validate(purchaseOrderId);
    const url = getUrl(path, { purchaseOrderId });
    return await axios.get<GetPurchaseOrderOutput>(url).then((res) => ({
      ...res.data,
      before: toDate(res.data.before),
      lastSendAt: toDate(res.data.lastSendAt),
      createdAt: toDate(res.data.createdAt),
    }));
  } catch (err) {
    return handleError(path, err, { purchaseOrderId });
  }
};

export const useGetPurchaseOrder = (
  purchaseOrderId: string | null,
): SWRResponse<GetPurchaseOrderOutput> => {
  const url = purchaseOrderId ? getUrl(path, { purchaseOrderId }) : null;
  return swr<GetPurchaseOrderOutput>(url, () => getPurchaseOrder(purchaseOrderId as string));
};
