import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import React, { useCallback, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';

import { ChatRoom } from '@/component/ChatRoom/ChatRoom';
import { ChatType } from '@/enum/index.ts';

import { ChatContext, OpenChatDto } from './ChatContext.ts';

type Props = {
  children: React.ReactNode;
};

const emptyTypes: ChatType[] = [];

export function ChatProvider({ children }: Props): React.ReactElement {
  const [types, setTypes] = useState<ChatType[]>(emptyTypes);
  const [docId, setDocId] = useState<string | null>(null);
  const [description, setDescription] = useState<string | undefined>();

  const onClose = useCallback(() => {
    setTypes(emptyTypes);
    setDocId(null);
    setDescription(undefined);
  }, []);

  const openChat = useCallback(
    (dto: OpenChatDto | null) => {
      if (dto === null) {
        onClose();
      } else {
        console.log('change openchat');
        setTypes([dto.type]);
        setDocId(dto.docId);
        setDescription(dto.description);
      }
    },
    [onClose],
  );

  const value = useMemo(() => ({ openChat }), [openChat]);
  const open = useMemo(() => types.length > 0 && docId !== null, [types, docId]);

  return (
    <ChatContext.Provider value={value}>
      {children}
      {createPortal(
        <Drawer sx={{ zIndex: 99998 }} anchor="right" open={open} onClose={onClose}>
          {open && docId && (
            <Box sx={{ width: '400px', height: '100%' }}>
              <ChatRoom types={types} docId={docId} description={description} onClose={onClose} />
            </Box>
          )}
        </Drawer>,
        document.body,
      )}
    </ChatContext.Provider>
  );
}
