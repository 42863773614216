import { Warning } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import { useMemo } from 'react';
import { Control, useFormContext } from 'react-hook-form';
import { useDebounce } from 'use-debounce';

import { useGetConfig } from '@/api/config/get-config';
import InputNumber from '@/field/InputNumber';
import { formatNumberWithSign } from '@/util/number';

type Props = {
  name: string;
  control: Control<any>;
  best?: number;
  label: string;
  onKeyUp?: (e: any) => any;
};

export function TreeSizeFormField({ name, control, best, label, onKeyUp }: Props) {
  const { data: config } = useGetConfig();
  const percentageErrorTreeSize = useMemo(() => config?.percentageErrorTreeSize || 10, [config]);

  const { watch } = useFormContext();

  const value = watch(name);

  const activeShowAlert = useMemo(() => {
    if (typeof best !== 'number') return false;
    if (typeof value !== 'number') return false;
    if (best === 0) return false;
    if (value === 0) return false;

    const diff = (percentageErrorTreeSize * best) / 100;
    if (best - diff > value) return true;
    if (best + diff < value) return true;

    return false;
  }, [best, value, percentageErrorTreeSize]);

  const [showAlert] = useDebounce(activeShowAlert, 500);

  return (
    <Stack justifyContent="center" direction="column" alignItems="center">
      <InputNumber decimalScale={0} name={name} control={control} label={label} onKeyUp={onKeyUp} />

      {best ? (
        <Box>
          {showAlert ? (
            <Typography
              sx={{ m: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              color="red"
              variant="caption"
            >
              <Warning sx={{ fontSize: 15, mr: '5px' }} />~
              <strong>
                {best} ({formatNumberWithSign(best - value)})
              </strong>
            </Typography>
          ) : (
            <Typography sx={{ m: 0, textAlign: 'center' }} variant="caption">
              ~{best}
            </Typography>
          )}
        </Box>
      ) : null}
    </Stack>
  );
}
