import BuildIcon from '@mui/icons-material/Build';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PaymentIcon from '@mui/icons-material/Payment';
import ReceiptIcon from '@mui/icons-material/Receipt';
import SellIcon from '@mui/icons-material/Sell';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useTranslation } from 'react-i18next';

import { useHeader } from '@/context';
import { MenuLevel1, MenuLevel2, ResellerRole, UserRole } from '@/enum';
import {
  useResellerHasRole,
  useUserHasSomeRole,
  useUserIsAdminReseller,
  useUserIsChilderic,
} from '@/hook/user';
import { I18nNs } from '@/util/i18n';

import { Level2Item } from '../../Level2Item/Level2Item';
import { StyledLevel2 } from '../Level2.style';

export function Level2Order() {
  const isChilderic = useUserIsChilderic();
  const hasSomeRole = useUserHasSomeRole();
  const isAdminReseller = useUserIsAdminReseller();
  const { t } = useTranslation(I18nNs.Menu);
  const { level1Path } = useHeader();

  const resellerHasRole = useResellerHasRole();
  const hasOrder = resellerHasRole(ResellerRole.ORDER);
  const hasInvoice = resellerHasRole(ResellerRole.INVOICE);

  if (level1Path !== MenuLevel1.Order) return null;

  if (!hasSomeRole(UserRole.Commercial, UserRole.AdminReseller, UserRole.Admin, UserRole.Delivery))
    return null;

  return (
    <StyledLevel2 component="ul">
      {hasOrder && (
        <Level2Item
          roles={[UserRole.Commercial, UserRole.AdminReseller, UserRole.Admin]}
          label={t('menu.order.list')}
          to={MenuLevel2.OrderList}
          icon={<ShoppingCartIcon />}
        />
      )}

      {hasInvoice && (
        <Level2Item
          roles={[UserRole.AdminReseller, UserRole.Admin]}
          label={t('menu.order.invoice')}
          to={MenuLevel2.OrderInvoice}
          icon={<ReceiptIcon />}
        />
      )}

      {isChilderic && (
        <Level2Item
          roles={[UserRole.Payment, UserRole.AdminReseller, UserRole.Commercial]}
          label={t('menu.order.payment')}
          to={MenuLevel2.OrderPayment}
          icon={<PaymentIcon />}
        />
      )}

      {isAdminReseller && !isChilderic && (
        <Level2Item
          roles={[UserRole.AdminReseller]}
          label={t('menu.order.childeric')}
          to={MenuLevel2.OrderChilderic}
          icon={<ShoppingCartIcon />}
        />
      )}

      {isChilderic && (
        <Level2Item
          roles={[UserRole.Commercial, UserRole.AdminReseller, UserRole.Admin]}
          label={t('menu.order.saddle-stock')}
          to={MenuLevel2.OrderSaddleStock}
          icon={<SellIcon />}
        />
      )}

      <Level2Item
        roles={[UserRole.Admin, UserRole.AdminReseller, UserRole.Commercial, UserRole.RepairRead]}
        label={t('menu.order.repair')}
        to={MenuLevel2.OrderRepair}
        icon={<BuildIcon />}
      />

      {isChilderic && (
        <Level2Item
          roles={[UserRole.Delivery]}
          label={t('menu.order.delivery')}
          to={MenuLevel2.OrderDelivery}
          icon={<LocalShippingIcon />}
        />
      )}
    </StyledLevel2>
  );
}
