import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import { useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import SelectSearchUserForChat from '@/field/SelectSearchUserForChat';
import { SubmitButton } from '@/field/SubmitButton/SubmitButton';
import { chatSocket } from '@/socket/chat';
import { I18nNs } from '@/util/i18n';

import { useChatStore } from '../../store/chat.store';

export function InfoAddUser() {
  const [open, setOpen] = useState<boolean>(false);
  const { t } = useTranslation(I18nNs.Chat);
  const room = useChatStore((s) => s.room);

  const methods = useForm<{ user: string }>();

  const { control, handleSubmit } = methods;

  const onSubmit = useCallback(
    async (data: { user: string }) => {
      if (!room) return;
      if (data.user === undefined) {
        setOpen(false);
        return;
      }

      chatSocket.emit('addUserInChat', { ...room.toJSON(), userId: data.user });
      setOpen(false);
    },
    [room],
  );

  return (
    <>
      <ListItemButton sx={{ pl: 0, pr: 0 }} onClick={() => setOpen(true)}>
        <ListItemIcon>
          <AddIcon sx={{ ml: 1 }} />
        </ListItemIcon>
        <ListItemText>{t('message.members.add')}</ListItemText>
      </ListItemButton>

      <Dialog fullWidth onClose={() => setOpen(false)} open={open} sx={{ zIndex: 99999 }}>
        <DialogTitle>{t('title.members.add')}</DialogTitle>
        <DialogContent>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack sx={{ mt: 1 }} direction="row" spacing={1}>
                <SelectSearchUserForChat control={control} name="user" label={t('field.member')} />
                <Stack sx={{ width: 100 }} direction="row" justifyContent="stretch">
                  <SubmitButton control={control} label={t('btn.add')} />
                </Stack>
              </Stack>
            </form>
          </FormProvider>
        </DialogContent>
      </Dialog>
    </>
  );
}
