import { GetTreeOutput } from '@/api/tree/get-tree';

export type TreeSizeItem = {
  largeur: number;
  enfourchure: number;
  longueur: number;
  hauteurArcade: number;
  hauteurTroussequin: number;
  ouvertureArcade: number;
};

export type TreeSize = {
  id: string;
  received: TreeSizeItem;
  strapping: TreeSizeItem;
};

export type TreeSizeDto = {
  received: TreeSizeItem;
  strapping: TreeSizeItem;
};

const defaultTreeSize = (): TreeSizeDto => ({
  received: {
    largeur: 0,
    enfourchure: 0,
    longueur: 0,
    hauteurArcade: 0,
    hauteurTroussequin: 0,
    ouvertureArcade: 0,
  },
  strapping: {
    largeur: 0,
    enfourchure: 0,
    longueur: 0,
    hauteurArcade: 0,
    hauteurTroussequin: 0,
    ouvertureArcade: 0,
  },
});

export const treeSizeToDto = (tree: GetTreeOutput | null): TreeSizeDto => {
  const base = defaultTreeSize();
  if (!tree) return base;

  return {
    received: {
      ...base.received,
      ...tree.received,
    },
    strapping: {
      ...base.strapping,
      ...tree.strapping,
    },
  };
};
